
import Badge from '@/components/status/Badge.vue'
import { Component, Mixins } from 'vue-property-decorator'
import { SearchBar, SearchBarItem } from '@/components/search'
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import Paging from '@/components/actions/Paging.vue'
import { CategorySelector, InputCheckbox, InputNumber, InputSelect, InputText, ZoneSelector } from '@/components/inputs'
import { FormPage } from '@/mixins'
import BusinessCreateModel from 'tradingmate-modules/src/models/api/businesses/BusinessCreateModel'
import BusinessBranchDisplayAddress
  from 'tradingmate-modules/src/models/api/businesses/BusinessBranchDisplayAddress'
import AdminUserCreateModel from 'tradingmate-modules/src/models/api/admin/users/AdminUserCreateDto'
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import BusinessBranchNumEmployees from 'tradingmate-modules/src/models/api/businesses/BusinessBranchNumEmployees'
import BusinessBranchModel from 'tradingmate-modules/src/models/api/businesses/BusinessBranchModel'
import Services from 'tradingmate-modules/src/services/Services'
import { UserModel } from 'tradingmate-modules/src/models/api/users'
import FormCheckboxField from '@/components/forms/FormCheckboxField.vue'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader,
    SearchBar,
    SearchBarItem,
    ZoneSelector,
    CategorySelector,
    Paging,
    Badge,
    InputText,
    InputSelect,
    InputCheckbox,
    InputNumber,
    FormCheckboxField
  }
})
export default class AdminBusinesses extends Mixins(FormPage) {
  private userCreateDto: AdminUserCreateModel = {
    FirstName: '',
    LastName: '',
    Email: ''
  }

  private businessCreateDto: BusinessCreateModel = {
    UserId: '',
    TradingName: '',
    ABN: '',
    NoAbn: false,
    NoABNReason: '',
    TradingAddress: '',
    Suburb: '',
    PostCode: '',
    PostalAddress: '',
    NonProfitCharity: false,
    NumEmployees: BusinessBranchNumEmployees.oneToFourteen,
    DisplayAddress: BusinessBranchDisplayAddress.Display
  };

  private NoAbn = false

  private displayAddressSelections: DropdownListItem[] = [
    { Key: 'Display', Label: 'Display', Value: BusinessBranchDisplayAddress.Display },
    { Key: 'Do not display', Label: 'Do not display', Value: BusinessBranchDisplayAddress.DoNotDisplay },
    { Key: 'Suburb only', Label: 'Suburb only', Value: BusinessBranchDisplayAddress.SuburbOnly }
  ]

  private numEmployees: DropdownListItem[] = [
    { Key: BusinessBranchNumEmployees[0], Label: '1-14', Value: BusinessBranchNumEmployees.oneToFourteen },
    { Key: BusinessBranchNumEmployees[1], Label: '15+', Value: BusinessBranchNumEmployees.fifteenPlus }
  ]

  async handleBusinessCreate (): Promise<void> {
    this.httpError = null
    this.loading = true
    const um: UserModel | null = await this.createUser()
    if (um === null) { this.loading = false; return }
    this.businessCreateDto.UserId = um.UserId
    const bb: BusinessBranchModel | null = await this.createBusiness()
    if (bb === null) { this.loading = false; return }
    await this.$router.push({ name: 'Business Branch Edit', params: { id: bb.BusinessBranchId } })
  }

  private async createUser (): Promise<UserModel | null> {
    console.log('running create business')
    try {
      const response = await Services.API.Admin.Users.Create(this.userCreateDto)
      return response
    } catch (error) {
      this.httpError = error
      return null
    }
  }

  private async createBusiness (): Promise<BusinessBranchModel | null> {
    console.log('running create business')
    try {
      const response = await Services.API.Admin.Businesses.Create(this.businessCreateDto)
      return response
    } catch (error)
    {
      this.httpError = error
      return null
    } finally {
      console.log('han')
    }
  }
}

